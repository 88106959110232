import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './FormQuestion.module.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import * as reviewMemberService from '~/services/reviewMemberService';
import { useRef } from 'react';
import routesConfig from '~/config/routes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '~/utils/main';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Moment from 'moment';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';

const cx = classNames.bind(styles);

const FormQuestion = () => {
    const [listQuestion, setListQuestion] = useState([]);
    const [infoRv, setInfoRv] = useState({});
    const [checkUpdateOrAdd, setCheckUpdateOrAdd] = useState(false); //false : Update; true : Add

    const [answers, setAnswers] = useState([]);
    const [validAnswer, setValidAnswer] = useState(false);

    const auth = useSelector((state) => state.auth);
    const { userEmail, userName } = auth.users;

    const textRef = useRef();
    const params = useParams();

    const [dateToday, setDateToday] = useState(Moment(new Date()).format('DD-MM-YYYY'));

    const [checkDueDate, setCheckDueDate] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reviewed, setReviewed] = useState(false);

    const typeUser = auth.users.userType === 3 ? 'answerOfMember' : 'answerOfLeader';

    useEffect(() => {
        getAllQuestionRv();
        getInfoRv();
    }, []);

    const getInfoRv = async () => {
        const res = await reviewMemberService.getInfoReviewOfMember(params.id);
        if (res.status === 200) {
            if (res.data.data?.answers?.length > 0) {
                setAnswers(res.data.data.answers);
                setReviewed(true);
            }
            setInfoRv(res.data.data);
            const dueDate = res.data.data?.dueDate;
            const dateDueDate = Moment(dueDate).format('DD-MM-YYYY');

            if (dateToday < dateDueDate || dateToday === dateDueDate) {
                setCheckDueDate(1);
            }

            if (dateToday > dateDueDate) {
                setCheckDueDate(2);
            }

            if (res.data.data?.answers?.length > 0) {
                setCheckUpdateOrAdd(false);
            } else {
                setCheckUpdateOrAdd(true);
            }
        }
    };

    const getAllQuestionRv = async () => {
        const res = await reviewMemberService.getAllQuestionReview();
        if (res.status === 200) {
            setListQuestion(res.data.data);
        }
    };

    // const isExitsAnswer = (answers) => {
    //     return answers !== null ? true : false;
    // };

    const handleChangeAnswer = (e, id) => {
        // setValidAnswer(false);

        //false : Update; true : Add
        if (checkUpdateOrAdd) {
            if (!answers.some((ele) => ele.questionId === id)) {
                setAnswers([...answers, { questionId: id, [typeUser]: e.target.value }]);
            } else {
                const clone = [...answers];

                const eleChangeIndex = clone.findIndex((ele) => ele.questionId === id);
                clone[eleChangeIndex][typeUser] = e.target.value;
                setAnswers(clone);
            }
        } else {
            const clone = [...answers];

            const eleChangeIndex = clone.findIndex((ele) => ele.questionId === id);
            clone[eleChangeIndex].answer = e.target.value;
            clone[eleChangeIndex].isChanged = true;
            setAnswers(clone);
        }
    };

    const handleSaveReview = async (e) => {
        e.preventDefault();

        if (answers.length === 0) {
            setValidAnswer(true);
        }

        if (checkUpdateOrAdd) {
            //add

            listQuestion.map((gr) => {
                gr.questionReviews.map((qs) => {
                    if (!answers.some((answer) => answer.questionId === qs.id)) {
                        const answerNull = { [typeUser]: '', questionId: qs.id };
                        answers.push(answerNull);
                    }
                });
            });
            const bodyPost = {
                reviewId: Number(params.id),
                answerOfUser: answers,
            };

            const filterBodyPost = { ...bodyPost, answerOfUser: answers };

            if (auth.users.userType === 3 || auth.users.userType === 4) {
                const res = await reviewMemberService.postAnswerFromReview(filterBodyPost);
                if (res.status === 200) {
                    getInfoRv();
                }
            }
        } else {
            const numberP = Number(params.id);

            const answerChanged = answers.filter((ele) => ele.isChanged);

            const parseAnswers = answerChanged.map((obj) => {
                const { questionId, isChanged, ...rest } = obj;
                return rest;
            });

            //edit
            const bodyEdit = {
                reviewId: Number(params.id),
                InfoEditQuestion: parseAnswers,
            };

            if (auth.users.userType === 3 || auth.users.userType === 4) {
                const res = await toast.promise(api.patch(`review-member/user/${numberP}`, bodyEdit), {
                    pending: 'Update review results pending',
                    success: 'Update review results successfully',
                    error: 'Update review results failed',
                });

                if (res.status === 204) {
                    getInfoRv();
                }
            }
        }
    };

    const getAnswer = (questionId) => {
        return answers.some((ele) => ele.questionId === questionId)
            ? answers.find((answer) => answer.questionId === questionId).answer
            : '';
    };

    const generateAlphabet = (startChar, endChar) => {
        const startCode = startChar.charCodeAt(0);
        const endCode = endChar.charCodeAt(0);
        const result = [];

        for (let i = startCode; i <= endCode; i++) {
            const currentChar = String.fromCharCode(i);
            result.push(currentChar);
        }

        return result;
    };

    const handleSelectLevel = (id, level) => {
        //false : Update; true : Add
        if (checkUpdateOrAdd) {
            if (!answers.some((ele) => ele.questionId === id)) {
                setAnswers([...answers, { questionId: id, level }]);
            } else {
                const clone = [...answers];

                const eleChangeIndex = clone.findIndex((ele) => ele.questionId === id);
                clone[eleChangeIndex].level = level;
                setAnswers(clone);
            }
        } else {
            const clone = [...answers];

            const eleChangeIndex = clone.findIndex((ele) => ele.questionId === id);
            clone[eleChangeIndex].level = level;
            clone[eleChangeIndex].isChanged = true;
            setAnswers(clone);
        }
    };

    const checkLevelActive = (id, level) => {
        const eleChangeIndex = answers.findIndex((ele) => ele.questionId === id);

        if (eleChangeIndex !== -1 && answers[eleChangeIndex].level === level) {
            return true;
        }

        return false;
    };

    return (
        <div className={cx('wrapper-review-form')}>
            <div className={cx('review-form-head')}>
                <p className={cx('mt-4', 'mb-4', 'review-form-title')}>Review Form</p>
            </div>
            {checkDueDate === 1 && (
                <div className={cx('rev-form-fill')}>
                    <div className="rev-name-type my-2">
                        <h2 className={cx('rev-title')}>EMPLOYEE PERFORMANCE REVIEW</h2>
                    </div>
                    <div className={cx('rev-information-staff', 'p-4')}>
                        <div className={cx('row', 'glo-row')}>
                            <div className={cx('col-12 col-xl-4')}>
                                <p className={cx('rev-txt-employee')}>
                                    Reviewer name: <span dangerouslySetInnerHTML={{ __html: infoRv.userName }}></span>
                                </p>
                            </div>
                            <div className={cx('col-12 col-xl-4')}>
                                <p className={cx('rev-txt-employee')}>
                                    Email: <span>{infoRv.emailUser}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={cx('rev-date', 'mb-2')}>
                        <div className={cx('row')}>
                            <label className={cx('col-xl-1 col-form-label')}>Review date:</label>
                            <div className={cx('col-xl-2')}>
                                <div className={cx('row')}>
                                    <label
                                        htmlFor="monthReview"
                                        className={cx('col-sm-5', 'col-form-label', 'text-end')}
                                    >
                                        Month
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="number"
                                            className={cx('form-control', 'form-control-lg')}
                                            id="monthReview"
                                            value={infoRv.month}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={cx('col-xl-2')}>
                                <div className={cx('row')}>
                                    <label
                                        htmlFor="yearReview"
                                        className={cx('col-sm-4', 'col-form-label', 'text-end')}
                                    >
                                        Year
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="number"
                                            className={cx('form-control', 'form-control-lg')}
                                            id="yearReview"
                                            value={infoRv.year}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {validAnswer && (auth.users.userType === 3 || auth.users.userType === 4) && (
                        <p className={cx('text-danger')}>You have not answered the question</p>
                    )}
                    {validAnswer && (auth.users.userType === 1 || auth.users.userType === 2) && (
                        <p className={cx('text-danger')}>You must not review</p>
                    )}
                    <div className={cx('rev-list-question-answer')}>
                        {listQuestion.map((listQt, idxParent) => {
                            return (
                                <div key={idxParent}>
                                    <h2 className={cx('text-group-name')}>{listQt.questionName}</h2>
                                    {listQt?.questionReviews?.map((question, idx) => {
                                        return (
                                            <div className={cx('rev-question-answer')} key={question.id}>
                                                <div className={cx('rev-question')}>
                                                    <span>
                                                        <b>Question {question.orderNumber}: </b>
                                                        {question.question}
                                                    </span>
                                                    <div className={cx('wrap-level')}>
                                                        {question.fromLevel
                                                            ? generateAlphabet(
                                                                  question.fromLevel,
                                                                  question.toLevel,
                                                              ).map((level) => (
                                                                  <div
                                                                      key={level}
                                                                      className={cx(
                                                                          'level',
                                                                          `${
                                                                              checkLevelActive(question.id, level)
                                                                                  ? 'level-active'
                                                                                  : ''
                                                                          }`,
                                                                      )}
                                                                      onClick={() =>
                                                                          handleSelectLevel(question.id, level)
                                                                      }
                                                                  >
                                                                      {level}
                                                                  </div>
                                                              ))
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className={cx('rev-answer my-3')}>
                                                    <textarea
                                                        ref={textRef}
                                                        className={cx(
                                                            'form-control',
                                                            'clear-answer-value',
                                                            'font-text-area',
                                                        )}
                                                        value={
                                                            getAnswer(question.id)
                                                            // infoRv?.answers?.length > 0
                                                            //     ? infoRv?.answers[question.id - 1]
                                                            //     : ''
                                                        }
                                                        id={`answer${question.id}`}
                                                        rows="4"
                                                        onChange={(e) => handleChangeAnswer(e, question.id)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                    <div className={cx('mt-4', 'mb-4', 'rev-form-action', 'text-end')}>
                        {checkUpdateOrAdd ? (
                            <button
                                type="submit"
                                onClick={handleSaveReview}
                                className={cx('hn-btn-submit')}
                                size="medium"
                            >
                                Add
                            </button>
                        ) : (
                            <button
                                type="submit"
                                onClick={handleSaveReview}
                                className={cx('hn-btn-submit')}
                                size="medium"
                            >
                                Update
                            </button>
                        )}
                    </div>
                </div>
            )}

            {checkDueDate === 2 && (
                <div className={cx('notice-oops')}>
                    <h1 className={cx('notice-oops-text', 'text-center')}>Oops!</h1>
                    <p className={cx('text-center', 'notice-duedate')}>Review period expires</p>
                </div>
            )}
        </div>
    );
};

export default FormQuestion;
