import classNames from 'classnames/bind';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styles from '../CreateProject/CreateProjectDialog.module.scss';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import Qs from 'qs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import api from '~/utils/main';
import draftToHtml from 'draftjs-to-html';

const cx = classNames.bind(styles);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const initialState = {
    name: '',
    shortName: '',
    description: '',
    userAssignIds: [],
};

const menuTeams = [
    { id: 0, name: 'All' },
    { id: 1, name: 'G1' },
    { id: 2, name: 'G2' },
];

const CreateProjectDialog = ({ openDialogCreateProject, setOpenDialogCreateProject, listProject, getListProject }) => {
    const [dataCreate, setDataCreate] = useState(initialState);
    const { name, shortName, userAssignIds } = dataCreate;
    const [valueNameProject, setValueNameProject] = useState(false);
    const [valueNameProjectCheck, setValueNameProjectCheck] = useState(false);
    const [teamId, setTeamId] = useState(0);

    const inputNameProject = useRef();
    const [valueShortName, setValueShortName] = useState(false);
    const [isCheckAddUser, setIsCheckAddUser] = useState(false);

    const [listUserAssign, setListUserAssign] = useState([]);
    const token = useSelector((state) => state.token);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        return setEditorState(editorState);
    };

    const handleCloseCreateProjectDialog = () => {
        setOpenDialogCreateProject(false);
        setDataCreate(initialState);
        setEditorState(EditorState.createEmpty());
    };

    const handleChangeNameProject = (value) => {
        setValueNameProject(false);
        setValueNameProjectCheck(false);
        setDataCreate({ ...dataCreate, name: value });
    };

    const handleChangeShortNameProject = (value) => {
        setValueShortName(false);
        setDataCreate({ ...dataCreate, shortName: value });
    };

    const handleCheckUser = (e, id) => {
        const { checked } = e.target;
        setIsCheckAddUser(!isCheckAddUser);

        // setDataCreate({ ...dataCreate, userAssignIds: [...userAssignIds, { id: id, name: name }] });
        setDataCreate((prevState) => {
            const newState = { ...prevState };
            const arrPush = id;
            newState.userAssignIds.push(arrPush);
            return newState;
        });

        if (!checked) {
            const newAssignUser = [...userAssignIds];
            const rs = newAssignUser.filter((item) => item.id !== id);
            setDataCreate({ ...dataCreate, userAssignIds: rs });
        }
    };

    const handleCheckedUser = (id) => {
        userAssignIds.map((addUser) => {
            return addUser.id === id;
        });
    };

    useEffect(() => {
        setEditorState('');
        getListUserAssign();
    }, []);

    const getListUserAssign = async () => {
        const response = await api.get(`projects/init-create`).then((response) => {
            setListUserAssign(response.data.data);
        });
    };

    const handleCreateProject = async (e) => {
        e.preventDefault();

        if (name === '') {
            setValueNameProject(true);
            setValueShortName(true);
            inputNameProject.current.focus();
            return;
        }

        if (shortName === '') {
            setValueShortName(true);
            return;
        }

        const checkValueNamePro = listProject.map((chk) => {
            if (chk.name === name || chk.name === shortName) {
                setValueNameProjectCheck(true);
            }
            return;
        });

        if (checkValueNamePro) {
            const body = {
                name: name,
                shortName: shortName,
                description: draftToHtml(convertToRaw(editorState.getCurrentContent())) || '',
                userAssignIds: userAssignIds,
                teamId: teamId,
            };

            const res = await api.post(`projects`, body);

            if (res.data.status === 'success') {
                handleCloseCreateProjectDialog();
                getListProject();
                setDataCreate({
                    name: '',
                    shortName: '',
                    description: '',
                    userAssignIds: [],
                    teamId: 0,
                });
                setEditorState('');
            }
        }
    };

    const handleChangeTeam = (teamId) => {
        setTeamId(teamId);
    };

    return (
        <div className={cx('header-create-task')}>
            <form>
                <BootstrapDialog
                    open={openDialogCreateProject}
                    onClose={handleCloseCreateProjectDialog}
                    maxWidth="1700px"
                    minwidth="1500px"
                    fullWidth
                    aria-labelledby="customized-dialog-title"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseCreateProjectDialog}
                        className={cx('custom-title-control')}
                    >
                        <div className={cx('dialog-create-project-title')}>
                            <h2>Create Project</h2>
                        </div>
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className={cx('dialog-create-project-content')}>
                            <div className={cx('row')}>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-name" className="col-lg-2 col-form-label">
                                            Project Name<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-10">
                                            <input
                                                ref={inputNameProject}
                                                type="text"
                                                className="form-control form-control-lg mb-3"
                                                id="project-name"
                                                value={name}
                                                onChange={(e) => handleChangeNameProject(e.target.value)}
                                            ></input>
                                            {valueNameProject ? (
                                                <span className={cx('error-message')}>Project name must not be blank</span>
                                            ) : null}
                                            {valueNameProjectCheck ? (
                                                <span className={cx('error-message')}>
                                                    Project name or short name is already exist!
                                                </span>
                                            ) : null}
                                        </div>

                                    </div>
                                </div>
                                <div className={cx('col-12 col-md-6 mb-3')}>
                                    <div className={cx('form-group row')}>
                                        <label htmlFor="project-short-name" className="col-lg-2 col-form-label">
                                            Short Name<i className={cx('hn-color-red')}>*</i>
                                        </label>
                                        <div className="col-lg-10">
                                            <input
                                                type="text"
                                                className="form-control form-control-lg mb-3"
                                                id="project-short-name"
                                                value={shortName}
                                                onChange={(e) => handleChangeShortNameProject(e.target.value)}
                                            ></input>
                                            {valueShortName ? (
                                                <span className={cx('error-message')}>The short name must not be blank</span>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div className={cx('wrap-btn-team')}>
                                {menuTeams.map((team) => (
                                    <div className={cx('cursor-pointer')} key={team.id}>
                                        <input
                                            id={`radio-project-${team.name}`}
                                            type="radio"
                                            className={cx('cursor-pointer')}
                                            checked={teamId == team.id}
                                            value={team.id}
                                            onChange={() => handleChangeTeam(team.id)}
                                        ></input>
                                        <label htmlFor={`radio-project-${team.name}`} className={cx('label-team')}>
                                            {team.name}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <div className="mb-4 mt-4">
                                <label htmlFor="add-user-project" className="form-label">
                                    Add Users (optional)
                                </label>
                                <div className={cx('row')}>
                                    {listUserAssign.map((userAssign, index) => {
                                        return (
                                            <div className={cx('col-12 col-md-6 col-lg-4 col-xl-3')} key={index}>
                                                <input
                                                    checked={handleCheckedUser(userAssign.id)}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id={'checkBoxAddUser' + userAssign.id}
                                                    onChange={(e) => handleCheckUser(e, userAssign.id)}
                                                />
                                                <label
                                                    className={cx('form-check-label', 'lbl-name_add-user')}
                                                    htmlFor={'checkBoxAddUser' + userAssign.id}
                                                >
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: userAssign.name,
                                                        }}
                                                    />
                                                    &nbsp;
                                                    {userAssign.userType === 'Member' ? null : (
                                                        <>
                                                            <span>({userAssign.userType})</span>
                                                        </>
                                                    )}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="mb-4 mt-4">
                                <div className="row">
                                    <div className="col-12">
                                        <label
                                            htmlFor="hn-create-description"
                                            className={cx('form-label', 'form-label-title')}
                                        >
                                            Description
                                        </label>
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={onEditorStateChange}
                                            wrapperClassName="wrapper-class res-description"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            placeholder="Enter your comment...."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={cx('create-project-action')}>
                        <button onClick={handleCloseCreateProjectDialog} className={cx('hn-btn-cancel')} size="medium">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            onClick={handleCreateProject}
                            className={cx('hn-btn-submit')}
                            size="medium"
                        >
                            Add
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </form>
        </div>
    );
};

export default CreateProjectDialog;
